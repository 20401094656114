import consumer from "./consumer"

consumer.subscriptions.create("ScheduleNotifyChannel", {
  connected() {},

  disconnected() {},

  received(data) {
    if($('.box-switch').attr('data-notify') && $('#schedule_sound_select').length) {
      const timeStartsAt = moment(new Date(data.content.starts_at));

      switch($('#schedule_time_give_notice').attr('data-selected')) {
        case 'within_2_hours':
          if(timeStartsAt.format('YYYY-MM-DD HH:mm:ss') >= moment().format('YYYY-MM-DD HH:mm:ss') &&
             timeStartsAt.format('YYYY-MM-DD HH:mm:ss') <= moment().add(2, 'h').format('YYYY-MM-DD HH:mm:ss'))
            giveNotice();
          break;
        case 'today':
          if(timeStartsAt.format('YYYY-MM-DD') == moment().format('YYYY-MM-DD'))
            giveNotice();
          break;
        case 'within_next_2_days':
          if(timeStartsAt.format('YYYY-MM-DD') >= moment().format('YYYY-MM-DD') &&
             timeStartsAt.format('YYYY-MM-DD') <= moment().add(2, 'd').format('YYYY-MM-DD'))
            giveNotice();
          break;
        default:
          giveNotice();
      }

      function giveNotice() {
        let stylistName = `フリー予約 (${data.content.stylist_name} が担当)`;
        if(data.content.stylist_id == null)
          stylistName = 'フリー予約 (担当未定)';
        else if(data.content.stylist_required)
          stylistName = `${data.content.stylist_name} (指名予約)`;
        else if(data.content.rental)
          stylistName = `${object.stylist_name}(面貸し)`;

        $('.notify-content .stylist-info').html(`${stylistName}`);
        $('#box-message-notify').modal({ backdrop: false });

        playSound();
        
        setTimeout(function(){ $('#box-message-notify').modal('hide') }, 60000);
      }

      function playSound() {
        $(`.box-audio #sound-${$('#schedule_sound_select').attr('data-selected')}`)[0].play();
        $(`.box-audio #sound-${$('#schedule_sound_select').attr('data-selected')}`)[0].volume = parseInt($('#schedule_volume').attr('data-volume')) / 100;

        let timesPlay = parseInt($('#schedule_times_play').attr('data-selected'));

        $('.box-audio audio').on('ended', function() {
          --timesPlay;
          if(timesPlay > 0) {
            $(`.box-audio #sound-${$('#schedule_sound_select').attr('data-selected')}`)[0].play();
          }
        });
      }

      $('body').on('click', '.notify-content .stop-audio', function() {
        $(`.box-audio #sound-${$('#schedule_sound_select').attr('data-selected')}`)[0].pause();
      });
    }
  }
});
