$(document).ready(function () {
  window.numberWithComma = function(number) {
    return number.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ',');
  }

  $('input.number-with-comma').each(function(index, selector) {
    $(selector).val(numberWithComma($(selector).val()));
  });

  $(document).on('input', 'input.number-with-comma', function() {
    const value = this.value.replace(/[^０-９0-9]/g,'');
    let maxNumber = parseInt($(this).attr('maxNumber'));
    if($(this).attr('maxNumber') == undefined)
      maxNumber = parseInt($(this).attr('maxlength')) - parseInt(parseInt($(this).attr('maxlength')) / 3) + 1 ;
    if (value.length > maxNumber) {
      $(this).val(value.slice(0, maxNumber));
    } else
      $(this).val(value);
  });

  $(document).on('focusout', '.number-with-comma',function(){
    const value = $(this).val().replace(/[^０-９0-9]/g,'');
    if(value.toHalfWidth() == '')
      $(this).val('');
    else
      $(this).val(numberWithComma(parseInt(value.toHalfWidth())));
  });

  $(document).on('keypress', 'input', function (e) {
    if (e.which == 13 && window.location.pathname != '/users/sign_in') e.preventDefault();
  });
});
