$(document).on('input', '.numeric:not(.number-with-comma)', function() {
  const value = this.value.replace(/[^０-９0-9]/g,'');
  const maxlength = parseInt($(this).attr('maxlength'));
  if (value.length > maxlength) {
    $(this).val(value.slice(0, maxlength));
  } else
    $(this).val(value);
});

$(document).on('focusout', '.numeric:not(.number-with-comma)',function(){
  const value = $(this).val().replace(/[^０-９0-9]/g,'');
  if(value.toHalfWidth() == '')
    $(this).val('');
  else
    $(this).val(parseInt(value.toHalfWidth()));
});

$(document).on('input', '.decimal', function(event) {
  if($(this).val().match(/\d{3}|\d{1}.\d{2}|\d{1}\.\.|\d{2}\.\.|[^\d{1}\.]|^\./g))
    $(this).val($(this).val().slice(0, -1));
  if($(this).val() == '.')
    $(this).val('');

  if ((event.which != 46 || $(this).val().indexOf('.') != -1) && (event.which < 48 || event.which > 57)) {
    $(this).val($(this).val().replace(/[^0-9\.]/g,''));
    event.preventDefault();
  }
});

$(document).on('change', '.trailing-space', function(event) {
  this.value = this.value.replace(/\\s*/g,'');
  event.stopImmediatePropagation();
});

$(document).on('input', '.percentage', function(e) {
  let value = this.value;
  this.value = value.replace(/\D/g,'');
  if (value.length >= 3 && value > 100) {
    if (this.value == 1000) {
      this.value = value.slice(0, 3);
    }
    else {
      this.value = value.slice(0, 2);
    }
  }
});

$(document).on('blur', '.trim-space', function() {
  if (this.value.split('')[0] == ' ' || this.value.split('')[this.value.split('').length -1] == ' ') {
    this.value = this.value.trim();
  }
  else if (this.value.split('')[0] == '　' || this.value.split('')[this.value.split('').length -1] == '　') {
    this.value = this.value.trim();
  }
});

String.prototype.toHalfWidth = function() {
  return this.replace(/[Ａ-Ｚａ-ｚ０-９]/g, function(s) {return String.fromCharCode(s.charCodeAt(0) - 0xFEE0)});
};
