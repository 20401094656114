window.charactersCount = function(str) {
  if(str == undefined)
    return 0 ;

  let length = 0;
  const strLength = str.length;
  for (let i = 0; i < strLength; i++) {
    let code = str.charCodeAt(i);
    if ((code >= 0x0020 && code <= 0x1FFF) || (code >= 0xFF61 && code <= 0xFF9F))
      length += 0.5;
    else if ((code >= 0x2000 && code <= 0xFF60) || (code >= 0xFFA0))
      length += 1;
    else
      length += 0;
  }
  return length;
}
